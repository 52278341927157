.landingpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.landingpage > img {
  object-fit: contain;
  width: 12rem;
}

.landingpage__nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
}

.landingpage__nav video,
.landingpage__nav img {
  width: 28rem;
}

.landingpage__nav-option {
  outline: 2px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.landingpage__nav-option:hover {
  outline: 4px solid;
}

.landingpage__nav-option h1 {
  color: white;
  position: absolute;
  top: -0.3rem;
  left: 1rem;
  font-size: 3.75rem;
  font-weight: 500;
  letter-spacing: -0.05em;
}

@media screen and (min-width: 1550px) {
  .landingpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px - 3rem);
  }
}

@media screen and (max-width: 1000px) {
  .landingpage__nav {
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .landingpage__nav video,
  .landingpage__nav img {
    width: 18rem;
  }

  .landingpage__nav-option {
    width: 20rem;
    height: 12rem;
  }

  .landingpage__nav-option h1 {
    font-size: 2.5rem;
  }
}
