.thoughtcitypage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 6rem;
  width: 100%;
  overflow-x: hidden;
}

.thoughtcitypage__sec2 {
  width: 100vw;
  object-fit: contain;
  margin-bottom: 6rem;
}

.thoughtcitysection18 {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 4rem;
}

.thoughtcitysection18-react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1300px) {
  .thoughtcitysection18 {
    height: 80vh;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 1100px) {
  .thoughtcitysection18 {
    height: 38vh;
  }
}

@media screen and (max-width: 1000px) {
  .thoughtcitypage {
    padding: 0 4rem;
  }

  .thoughtcitysection18 {
    height: 60vh;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitypage {
    padding: 0 1rem;
  }

  .thoughtcitypage__sec2 {
    margin-bottom: 1rem;
  }

  .thoughtcitysection18 {
    height: 30vh;
    margin-bottom: 4rem;
  }
}
