.spoofsensesideoside {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;
}

.spoofsensesideoside > img,
.spoofsensesideoside > video {
  width: 70%;
}

.spoofsensesideoside > video {
  border-radius: 3vw;
}

.spoofsensesideoside p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
}

@media screen and (max-width: 1220px) {
  .spoofsensesideoside p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1120px) {
  .spoofsensesideoside {
    margin-top: 2rem;
  }

  .spoofsensesideoside p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .spoofsensesideoside p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 750px) {
  .spoofsensesideoside {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .spoofsensesideoside > img,
  .spoofsensesideoside > video {
    width: 95%;
    margin: 0 auto;
  }

  .spoofsensesideoside p {
    text-align: left;
    position: relative;
    left: 1rem;
  }
}
