.bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 6rem;
  width: 100%;
  margin-top: 2rem;
}

.bio__header {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.bio__header > img {
  width: 30%;
}

.bio__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3rem;
}

.bio__content > div:nth-of-type(2) {
  width: 35%;
}

.bio__content > div:nth-of-type(2) > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.8rem;
  margin-top: 1rem;
}

.bio__content > div:nth-of-type(2) > ul > li > a {
  color: #6496f5;
  position: relative;
  top: -0.3rem;
}

.bio__content > div:nth-of-type(2) > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-size: 2.1rem;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: -0.05em;
  position: relative;
  top: -4rem;
}

.bio__content > div:nth-of-type(2) > ul > li > img {
  width: 2.5rem;
}

.bio__content > div:nth-of-type(2) > img {
  width: 100%;
  position: relative;
  bottom: 4rem;
}

.bio__content > div:nth-of-type(1) > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.8rem;
  line-height: 3rem;
}

.bio__content > div:nth-of-type(1) > a > h1 {
  color: #6496f5;
  text-decoration: underline;
  font-size: 4rem;
  margin-top: 3rem;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: -0.05em;
}

.bio__content > div:nth-of-type(1) > p > img {
  width: 20rem;
  margin-right: 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  position: relative;
  top: 0.7rem;
}

@media screen and (max-width: 1300px) {
  .bio__content > div:nth-of-type(1) > p {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  .bio__content > div:nth-of-type(2) > ul > li {
    font-size: 1.6rem;
  }

  .bio__content > div:nth-of-type(1) > a > h1 {
    font-size: 2.6rem;
  }

  .bio__content > div:nth-of-type(1) > p > img {
    width: 17rem;
  }
}

@media screen and (max-width: 1200px) {
  .bio__content > div:nth-of-type(2) > ul > li {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1100px) {
  .bio__content > div:nth-of-type(2) > ul > li > img {
    width: 1.8rem;
    margin-bottom: 0.3rem;
  }

  .bio__content > div:nth-of-type(2) > ul > li {
    gap: 0.2rem;
  }

  .bio__content > div:nth-of-type(2) > ul > li {
    font-size: 1.3rem;
  }

  .bio__content > div:nth-of-type(1) > p {
    font-size: 1.1rem;
    line-height: 2.2rem;
  }

  .bio__content > div:nth-of-type(1) > p > img {
    width: 14rem;
  }
}

@media screen and (max-width: 1000px) {
  .bio {
    padding: 0 4rem;
  }

  .bio__content > div:nth-of-type(1) > p {
    font-size: 1.1rem;
    line-height: 2.2rem;
  }

  .bio__content > div:nth-of-type(1) > p > img {
    width: 14rem;
  }

  .bio__content > div:nth-of-type(2) > ul > li {
    font-size: 1.2rem;
  }

  .bio__content > div:nth-of-type(2) > ul > li > img {
    width: 1.7rem;
    position: relative;
    bottom: 0.2rem;
  }
}

@media screen and (max-width: 750px) {
  .bio {
    padding: 0 1rem;
  }

  .bio__content {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .bio__content > div:nth-of-type(2) > img {
    bottom: 0;
  }

  .bio__header > img {
    width: 80%;
    margin: -2rem 0 0 0;
  }

  .bio__content > div:nth-of-type(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .bio__content > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .bio__content > div:nth-of-type(1) > p {
    font-size: 1.1rem;
    text-align: left;
    line-height: 2rem;
  }

  .bio__content > div:nth-of-type(1) > p > img {
    width: 10rem;
  }

  .bio__content > div:nth-of-type(2) > ul {
    gap: 0.4rem;
  }

  .bio__content > div:nth-of-type(2) > ul > li {
    top: 0;
    font-size: 1.5rem;
    gap: 1rem;
  }

  .bio__content > div:nth-of-type(1) > p > img {
    width: 12rem;
    margin: -0.5rem 0.2rem -0.2rem 0;
    position: relative;
    top: 0.7rem;
  }

  .bio__content > div:nth-of-type(1) > a > h1 {
    font-size: 2rem;
    margin: 2rem 0 0 0;
  }

  .bio__content > div:nth-of-type(2) > ul > li > img {
    width: 1.9rem;
  }
}
