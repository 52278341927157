.thoughtcitysection4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-bottom: 6rem;
}

.thoughtcitysection4 p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
  height: fit-content;
  text-align: start;
}

.thoughtcitysection4 > img {
  height: 27rem;
  object-fit: contain;
}

.thoughtcitysection4 > div {
  height: 27rem;
}

.thoughtcitysection4 > div:nth-of-type(2) {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection4 p {
    font-size: 1rem;
  }

  .thoughtcitysection4 > img {
    height: 23rem;
  }

  .thoughtcitysection4 > div {
    height: 23rem;
  }
}

@media screen and (max-width: 1120px) {
  .thoughtcitysection4 p {
    font-size: 0.9rem;
  }

  .thoughtcitysection4 > img {
    height: 19rem;
  }

  .thoughtcitysection4 > div {
    height: 19rem;
  }
}

@media screen and (max-width: 890px) {
  .thoughtcitysection4 p {
    font-size: 0.7rem;
  }

  .thoughtcitysection4 > img {
    height: 15rem;
  }

  .thoughtcitysection4 > div {
    height: 15rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection4 {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .thoughtcitysection4 p {
    font-size: 1rem;
  }

  .thoughtcitysection4 > img {
    width: 100%;
    height: auto;
  }

  .thoughtcitysection4 > div {
    height: auto;
    width: 100%;
  }

  .thoughtcitysection4 > div:nth-of-type(2) {
    align-items: center;
    justify-content: flex-end;
  }
}
