.thoughtcitysection11 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
  margin-bottom: 0rem;
}

.thoughtcitysection11 > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}

.thoughtcitysection11__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
}

.thoughtcitysection11__item > img {
  width: 17rem;
}

.thoughtcitysection11__item > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.2rem;
}

.thoughtcitysection11__line {
  width: 100%;
  height: 0.9rem;
  border: 1px solid #b0db36;
}

@media screen and (max-width: 1230px) {
  .thoughtcitysection11 {
    gap: 2rem;
  }

  .thoughtcitysection11__item {
    gap: 0.8rem;
  }

  .thoughtcitysection11__item > img {
    width: 10rem;
  }

  .thoughtcitysection11__item > p {
    font-size: 1.1rem;
  }

  .thoughtcitysection11__line {
    height: 0.7rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection11 {
  }

  .thoughtcitysection11 > div {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .thoughtcitysection11__item > img {
    width: 8rem;
  }

  .thoughtcitysection11__item > p {
    font-size: 1rem;
  }

  .thoughtcitysection11__line {
    height: 0.6rem;
  }
}
