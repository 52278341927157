.spoofsensecarousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 6rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.spoofsensecarousel__content {
  color: white;
  width: 82%;
}

.spoofsensecarousel__content > p {
  letter-spacing: -0.05em;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
}

.spoofsensecarousel .carousel-wrapper {
  width: 100%;
}

.spoofsensecarousel .carousel-wrapper img {
  width: 90%;
}

.spoofsensecarousel button.rec-dot {
  background-color: #131313;
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid #202020;
}

.spoofsensecarousel button.rec-dot_active {
  box-shadow: 0 0 1px 3px #19a3cf;
}

.spoofsensecarousel button.rec-arrow-right {
  background-color: #131313;
  color: white;
}

.spoofsensecarousel button.rec-arrow-right:hover,
.spoofsensecarousel button.rec-arrow-right:active,
.spoofsensecarousel button.rec-arrow-right:focus {
  background-color: #0c8ec6;
}

.spoofsensecarousel button.rec-arrow-left {
  background-color: #131313;
  color: white;
}

.spoofsensecarousel button.rec-arrow-left:hover,
.spoofsensecarousel button.rec-arrow-left:active,
.spoofsensecarousel button.rec-arrow-left:focus {
  background-color: #0c8ec6;
}

@media screen and (max-width: 1100px) {
  .spoofsensecarousel__content {
    width: 78%;
  }
}

@media screen and (max-width: 1000px) {
  .spoofsensecarousel {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 750px) {
  .spoofsensecarousel {
    padding: 0 1rem;
  }

  .spoofsensecarousel .carousel-wrapper {
    width: 100%;
  }

  .carousel-wrapper img {
    width: 100%;
  }

  .spoofsensecarousel button.rec-arrow-right {
    display: none;
  }

  .spoofsensecarousel button.rec-arrow-left {
    display: none;
  }

  .spoofsensecarousel__content {
    width: 87%;
  }

  .spoofsensecarousel__content > p {
    font-size: 1rem;
  }
}
