.thoughtcitysection2 {
  width: 100vw;
  object-fit: contain;
  margin-bottom: 4rem;
}

@media screen and (max-width: 750px) {
  .thoughtcitysection2 {
    margin-bottom: 1rem;
  }
}
