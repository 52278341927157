.spoofsensefullscreen {
  width: 100%;
  object-fit: contain;
  margin-bottom: 6rem;
}

@media screen and (max-width: 750px) {
  .spoofsensefullscreen {
    margin-bottom: 2rem;
  }
}
