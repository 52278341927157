.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6rem;
  background-color: #0f0f0f;
  height: 5rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header span {
  cursor: pointer;
  font-weight: 500;
  font-size: 1.3rem;
}

.header__left {
  flex: 0.23;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 1.2rem;
}

.header__center {
  flex: 0.54;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__right {
  flex: 0.23;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 1.2rem;
}

.header__center > img {
  object-fit: contain;
  width: 12rem;
  cursor: pointer;
}

.header_ham {
  display: none;
}

.header__mobileview {
  display: none;
}

@media screen and (max-width: 1000px) {
  .header {
    padding: 0 4rem;
  }

  .header__left {
    font-size: 1rem;
  }

  .header__right {
    font-size: 1rem;
  }

  .header__center > img {
    width: 10rem;
  }
}

@media screen and (max-width: 750px) {
  .header {
    margin: 0;
    padding: 0 1rem;
    justify-content: space-between;
  }

  .header__left {
    display: none;
  }

  .header__right {
    display: none;
  }

  .header__center {
    flex: 0;
  }

  .header_ham {
    display: block;
    color: white;
    font-size: 1.6rem;
    cursor: pointer;
  }

  .header__mobileview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #131313;
    position: absolute;
    width: 100%;
    height: calc(100vh - 80px);
    top: 80px;
    left: 0;
    color: white;
  }

  .header__mobileview h1 {
    font-weight: 600;
    font-size: 2.3rem;
    cursor: pointer;
  }
}
