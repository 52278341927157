.thoughtcitysection13 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.thoughtcitysection13 > div {
  width: 100%;
}

.thoughtcitysection13 p {
  color: white;
  font-family: "Manrope";
  font-size: 1.2rem;
}

.thoughtcitysection13 > div:nth-of-type(1) > img {
  width: 70%;
}

.thoughtcitysection13 > div:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6rem;
  position: relative;
  top: -8rem;
  left: -3rem;
}

.thoughtcitysection13 > div:nth-of-type(2) > video {
  width: 45%;
}

.thoughtcitysection13 > div:nth-of-type(3) {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6rem;
}

.thoughtcitysection13 > div:nth-of-type(3) > img {
  width: 44%;
}

.thoughtcitysection13 > div:nth-of-type(4) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: -14rem;
}

.thoughtcitysection13 > div:nth-of-type(4) > img {
  width: 44%;
}

.thoughtcitysection13 > div:nth-of-type(5) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: -9rem;
}

@media screen and (max-width: 1100px) {
  .thoughtcitysection13 p {
    font-size: 1rem;
  }

  .thoughtcitysection13 > div:nth-of-type(2) {
    top: -3rem;
    gap: 3rem;
  }

  .thoughtcitysection13 > div:nth-of-type(3) {
    gap: 3rem;
  }

  .thoughtcitysection13 > div:nth-of-type(5) {
    top: -12rem;
  }
}

@media screen and (max-width: 950px) {
  .thoughtcitysection13 p {
    font-size: 0.9rem;
  }

  .thoughtcitysection13 > div:nth-of-type(2) {
    top: -2rem;
    gap: 2rem;
  }

  .thoughtcitysection13 > div:nth-of-type(3) {
    gap: 2rem;
  }

  .thoughtcitysection13 > div:nth-of-type(4) {
    top: -12rem;
  }

  .thoughtcitysection13 > div:nth-of-type(5) {
    top: -8rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection13 {
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .thoughtcitysection13 p {
    font-size: 1rem;
  }

  .thoughtcitysection13 > div:nth-of-type(1) > img {
    width: 100%;
  }

  .thoughtcitysection13 > div:nth-of-type(2) {
    flex-direction: column-reverse;
    position: static;
  }

  .thoughtcitysection13 > div:nth-of-type(2) > video {
    width: 100%;
  }

  .thoughtcitysection13 > div:nth-of-type(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .thoughtcitysection13 > div:nth-of-type(3) > img {
    width: 100%;
  }

  .thoughtcitysection13 > div:nth-of-type(4) {
    justify-content: center;
    position: static;
  }

  .thoughtcitysection13 > div:nth-of-type(4) > img {
    width: 100%;
  }

  .thoughtcitysection13 > div:nth-of-type(5) {
    justify-content: center;
    position: static;
  }

  .thoughtcitysection13 > div:nth-of-type(5) > img {
    width: 100%;
    margin-bottom: 0;
  }
}
