.thoughtcitysection10 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-bottom: 10rem;
}

.thoughtcitysection10 > div:nth-of-type(1) {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(1) {
  width: 63%;
}

.thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(2) {
  width: 32%;
  position: relative;
  top: 12rem;
}

.thoughtcitysection10 > div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 2rem;
}

.thoughtcitysection10 > div:nth-of-type(2) > img {
  width: 62%;
}

.thoughtcitysection10 > div:nth-of-type(2) > p {
  width: 32%;
  position: relative;
  top: 4rem;
  color: white;
  font-family: "Manrope";
  font-size: 1.2rem;
}

@media screen and (max-width: 1320px) {
  .thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(2) {
    top: 8rem;
  }

  .thoughtcitysection10 > div:nth-of-type(2) > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1210px) {
  .thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(2) {
    top: 4rem;
  }

  .thoughtcitysection10 > div:nth-of-type(2) > p {
    font-size: 0.7rem;
  }

  .thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(2) {
    top: 9rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection10 {
    margin-bottom: 5rem;
    gap: 2rem;
  }

  .thoughtcitysection10 > div:nth-of-type(1) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 2rem;
  }

  .thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(1) {
    width: 100%;
  }

  .thoughtcitysection10 > div:nth-of-type(1) > img:nth-of-type(2) {
    width: 70%;
    position: static;
  }

  .thoughtcitysection10 > div:nth-of-type(2) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .thoughtcitysection10 > div:nth-of-type(2) > img {
    width: 100%;
  }

  .thoughtcitysection10 > div:nth-of-type(2) > p {
    width: auto;
    font-size: 1rem;
    position: static;
  }
}
