.thoughtcitycarousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 6rem;
  margin-top: 2rem;
}

.thoughtcitycarousel__content {
  color: white;
  width: 82%;
}

.thoughtcitycarousel__content > p {
  letter-spacing: -0.05em;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
}

.carousel-wrapper {
  width: 100%;
  margin-bottom: 6rem;
}

.carousel-wrapper img {
  width: 90%;
}

.thoughtcitycarousel button.rec-dot {
  background-color: #131313;
  width: 0.5rem;
  height: 0.5rem;
  border: 2px solid #202020;
}

.thoughtcitycarousel button.rec-arrow-right {
  background-color: #131313;
  color: white;
}

.thoughtcitycarousel button.rec-arrow-left {
  background-color: #131313;
  color: white;
}

@media screen and (max-width: 1300px) {
  .thoughtcitycarousel__content {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .thoughtcitycarousel__content {
    width: 78%;
  }
}

@media screen and (max-width: 1000px) {
  .thoughtcitycarousel {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitycarousel {
    padding: 0 1rem;
  }

  .carousel-wrapper img {
    width: 100%;
  }

  .thoughtcitycarousel button.rec-arrow-right {
    display: none;
  }

  .thoughtcitycarousel button.rec-arrow-left {
    display: none;
  }

  .thoughtcitycarousel__content {
    width: 94%;
  }

  .thoughtcitycarousel__content > p {
    font-size: 1rem;
  }
}
