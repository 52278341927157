@import url("https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@400;600;700&family=Manrope:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Familjen Grotesk", sans-serif;
  font-weight: 400;
  background-color: #131313;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
