.thoughtcitysection8 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 6rem;
}

.thoughtcitysection8 > img {
  width: 45%;
}

.thoughtcitysection8 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
  /* position: relative;
  right: 4rem; */
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection8 > p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 950px) {
  .thoughtcitysection8 > p {
    font-size: 0.9rem;
  }

  .thoughtcitysection8 > img {
    width: 48%;
  }
}

@media screen and (max-width: 820px) {
  .thoughtcitysection8 {
    margin-top: 2rem;
  }

  .thoughtcitysection8 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection8 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 0;
    margin-bottom: 4rem;
  }

  .thoughtcitysection8 > p {
    position: relative;
    left: 1.5rem;
  }

  .thoughtcitysection8 > img {
    width: 100%;
  }
}
