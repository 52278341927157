.charliesection10 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 6rem;
}

.charliesection10 > img {
  width: 90%;
  margin-bottom: 4rem;
}

.charliesection10 > img:nth-of-type(2),
.charliesection10 > img:nth-of-type(4) {
  position: relative;
  top: 15rem;
}

.charliesection10 > div {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.charliesection10 > div > div {
  position: relative;
  padding-top: 56.25%;
  width: 22rem;
  height: 38rem;
}

.charliesection10-vimeo {
  margin-top: -3rem;
  position: absolute;
  top: 0;
  left: 0;
}

.charliesection10 > p {
  position: relative;
  top: 15rem;
  color: white;
  font-size: 1.2rem;
  font-family: "Manrope";
}

@media screen and (max-width: 1220px) {
  .charliesection10 > p {
    font-size: 1rem;
  }

  .charliesection10 > div > div {
    width: 18rem;
  }
  .charliesection10-vimeo {
    margin-top: -5rem;
  }
}

@media screen and (max-width: 1100px) {
  .charliesection10 > img:nth-of-type(2),
  .charliesection10 > img:nth-of-type(4),
  .charliesection10 > p {
    top: 10rem;
  }

  .charliesection10 > div > div {
    width: 15rem;
  }
  .charliesection10-vimeo {
    margin-top: -7rem;
  }
}

@media screen and (max-width: 940px) {
  .charliesection10 > img:nth-of-type(2),
  .charliesection10 > img:nth-of-type(4),
  .charliesection10 > p {
    top: 8rem;
  }
}

@media screen and (max-width: 750px) {
  .charliesection10 {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .charliesection10 > img {
    margin-bottom: 0;
    width: 100%;
    margin: 0 auto;
  }

  .charliesection10 > img:nth-of-type(2),
  .charliesection10 > img:nth-of-type(4) {
    position: static;
  }

  .charliesection10 > div > img {
    margin-top: 0rem;
  }

  .charliesection10 > div {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .charliesection10 > div > div {
    width: 100%;
    height: 30rem;
  }
  .charliesection10-vimeo {
    margin-top: 0;
  }

  .charliesection10 > p {
    position: static;
    margin: 0 auto;
    text-align: center;
  }
}
