.loader {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > video {
  width: 100%;
}

.topSection__sec1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding: 7rem 0;
}

.topSection__sec1 > span {
  color: white;
  font-size: 1.6rem;
  letter-spacing: -0.05em;
  margin-bottom: 0.7rem;
}

.topSection__sec1__con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.topSection__sec1__con > p {
  color: white;
  font-size: 2.8rem;
  letter-spacing: -0.05em;
}

.topSection__sec1__con-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.topSection__sec1__con-right > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  align-items: flex-start;
  color: white;
}

.topSection__sec1__con-right > div > h4 {
  font-size: 1.6rem;
  font-weight: 500;
}

.topSection__sec1__con-right > div > p {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 1rem;
}

@media screen and (max-width: 1220px) {
  .topSection__sec1__con > p {
    font-size: 2rem;
  }

  .topSection__sec1__con-right > div > h4 {
    font-size: 1.2rem;
  }

  .topSection__sec1__con-right > div > p {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1120px) {
  .topSection__sec1__con > p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 820px) {
  .topSection__sec1__con > p {
    font-size: 1.4rem;
  }

  .topSection__sec1__con-right {
    gap: 0.5rem;
  }

  .topSection__sec1__con-right > div > h4 {
    font-size: 1.1rem;
  }

  .topSection__sec1__con-right > div > p {
    font-size: 0.7rem;
  }

  .topSection__sec1 > span {
    font-size: 1.4rem;
  }

  .topSection__sec1 {
    gap: 0.1rem;
  }
}

@media screen and (max-width: 750px) {
  .topSection__sec1 > span {
    font-size: 1.8rem;
  }

  .topSection__sec1 {
    gap: 0.8rem;
    padding: 1rem 0;
  }

  .topSection__sec1__con {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
  }

  .topSection__sec1__con > p {
    font-size: 1.7rem;
  }

  .topSection__sec1__con-right {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .topSection__sec1__con-right > div > h4 {
    font-size: 1.3rem;
  }

  .topSection__sec1__con-right > div > p {
    font-size: 0.8rem;
  }

  .topSection__sec1 > span {
    font-size: 1.4rem;
  }

  .topSection__sec1 {
    gap: 0.4rem;
  }
}
