.charliesection11 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;
}

.charliesection11 > img {
  width: 60%;
}

.charliesection11 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.4rem;
}

@media screen and (max-width: 1220px) {
  .charliesection11 > p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 820px) {
  .charliesection11 {
    margin-top: 2rem;
  }

  .charliesection11 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .charliesection11 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .charliesection11 > img {
    width: 95%;
    margin: 0 auto;
  }

  .charliesection11 > p {
    text-align: left;
    position: relative;
    left: 1rem;
  }
}
