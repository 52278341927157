.thoughtcitysection5 {
  width: 100vw;
  object-fit: contain;
  margin-bottom: 4rem;
}

@media screen and (max-width: 750px) {
  .thoughtcitysection5 {
    margin-bottom: 2rem;
  }
}
