.workpage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 18rem 0rem 18rem;
}

.workpage__projects {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.8rem;
}

.workpage__project {
  cursor: pointer;
}

.workpage__project img {
  width: 18rem;
}

.workpage__project-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.4rem;
  padding: 1.6rem;
  border: 2px solid white;
  color: white;
  width: 18rem;
  border-top: none;
  position: relative;
  top: -0.26rem;
}

.workpage__project-inner {
  font-size: 1.1rem;
}

.workpage__project-inner h1 {
  font-size: 2.6rem;
  font-weight: 500;
  letter-spacing: -0.05em;
}

.workpage__project-inner h3 {
  font-size: 1.4rem;
  font-weight: 500;
}

@media screen and (min-width: 1550px) {
  .workpage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 18rem 0rem 18rem;
    height: calc(100vh - 80px - 4.5rem);
  }
}

@media screen and (max-width: 1000px) {
  .workpage__projects {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 650px) {
  .workpage__projects {
    grid-template-columns: 1fr;
  }

  .workpage {
    margin: 0;
  }
}
