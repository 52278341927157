.thoughtcitysection17 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;
}

.thoughtcitysection17 > img {
  width: 60%;
}

.thoughtcitysection17 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
  position: relative;
  right: 3rem;
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection17 > p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1120px) {
  .thoughtcitysection17 {
    margin-top: 2rem;
  }

  .thoughtcitysection17 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 950px) {
  .thoughtcitysection17 > p {
    font-size: 0.9rem;
    right: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection17 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .thoughtcitysection17 > img {
    width: 100%;
  }

  .thoughtcitysection17 > p {
    right: -1.2rem;
  }
}
