.charliepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 6rem;
  width: 100%;
}

.charliepage__sec2 {
  width: 99vw;
  object-fit: contain;
  margin-bottom: 5rem;
}

.charliepage__sec13 {
  width: 99vw;
  object-fit: contain;
  margin-bottom: 2rem;
}

.charliepage__sec3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
}

.charliepage__sec3 > p {
  display: none;
}

.charliepage__sec3-top img {
  height: 30rem;
}

.charliepage__sec3-top {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 4rem;
  width: 100%;
}

.charliepage__sec3-top > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  height: 30rem;
}

.charliepage__sec3-top > div > h1 {
  font-family: "Work Sans";
  font-weight: 600;
  font-size: 2.4rem;
}

.charliepage__sec3-top > div > p {
  font-family: "Manrope";
  font-size: 1.4rem;
}

.charliepage__sec4 {
  width: 100%;
  object-fit: contain;
  margin-bottom: 3rem;
}

.charliepage__sec5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2.5rem;
}

.charliepage__sec5 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.6rem;
}

.charliepage__sec5 > img {
  width: 20rem;
}

.charliepage__sec6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 4rem;
}

.charliepage__sec6-top {
  width: 100%;
  grid-column: 1 / 3;
}

.charliepage__sec6-top + video {
  position: relative;
  left: -2.5rem;
  top: -6.2rem;
  width: 98%;
}

.charliepage__sec6-bottom {
  width: 98%;
  position: relative;
  top: 3rem;
}

.charliepage__sec7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.2rem;
  width: 100%;
  margin-bottom: 5rem;
}

.charliepage__sec7 > p {
  font-family: "Manrope";
  font-size: 1.6rem;
  color: white;
}

.charliepage__sec7 > video {
  width: 100%;
}

.charliepage__sec8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6rem;
  width: 100%;
  margin-bottom: 3rem;
}

.charliepage__sec8 > h1 {
  font-size: 13rem;
  color: white;
  font-family: "Work Sans";
  line-height: 57.8%;
  letter-spacing: -0.02em;
  font-weight: 900;
  margin-bottom: 2rem;
}

.charliepage__sec8 > img {
  width: 60%;
}

.charliepage__sec8 > div:nth-of-type(1) {
  color: white;
  display: flex;
  justify-content: end;
  gap: 0rem;
}

.charliepage__sec8 > div:nth-of-type(1) > img,
.charliepage__sec8 > div:nth-of-type(2) > img {
  width: 70%;
}

.charliepage__sec8 > div:nth-of-type(1) > span,
.charliepage__sec8 > div:nth-of-type(2) > span {
  transform: rotate(-90deg);
  width: fit-content;
  height: fit-content;
  position: relative;
  top: 5rem;
  right: -3rem;
  font-size: 1.8rem;
  font-family: "Manrope";
}

.charliepage__sec8 > div:nth-of-type(2) > span {
  right: 2rem;
}

.charliepage__sec8 > div:nth-of-type(2) {
  color: white;
  display: flex;
  justify-content: start;
  gap: 0rem;
  position: relative;
}

.charliepage__sec8 > div:nth-of-type(2) > span {
  transform: rotate(90deg);
  top: 4rem;
}

.charliepage__sec8 > div:nth-of-type(2) > p {
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0rem;
  right: 5rem;
  font-size: 1.3rem;
  font-family: "Manrope";
}

@media screen and (max-width: 1220px) {
  .charliepage__sec3-top img {
    height: 25rem;
  }

  .charliepage__sec3-top > div {
    height: 25rem;
  }

  .charliepage__sec3-top > div > h1 {
    font-size: 2rem;
  }

  .charliepage__sec3-top > div > p {
    font-size: 1rem;
  }

  .charliepage__sec8 > div:nth-of-type(2) > p {
    right: 2rem;
  }
}

@media screen and (max-width: 1120px) {
  .charliepage__sec3-top img {
    height: 22rem;
  }

  .charliepage__sec3-top > div {
    height: 22rem;
  }

  .charliepage__sec5 > p,
  .charliepage__sec7 > p {
    font-size: 1.2rem;
  }

  .charliepage__sec5 > img {
    width: 17rem;
  }

  .charliepage__sec6-top + img {
    left: -2rem;
    top: -3rem;
  }

  .charliepage__sec6-bottom {
    position: relative;
    top: 2rem;
  }

  .charliepage__sec8 > h1 {
    font-size: 10rem;
  }

  .charliepage__sec6-top + video {
    left: -1.7rem;
    top: -3.8rem;
  }
}

@media screen and (max-width: 1000px) {
  .charliepage {
    padding: 0 4rem;
  }

  .charliepage__sec8 > div:nth-of-type(2) > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 820px) {
  .charliepage__sec3-top img {
    height: 20rem;
  }

  .charliepage__sec3-top {
    gap: 2rem;
  }

  .charliepage__sec3-top > div {
    height: 20rem;
  }

  .charliepage__sec3-top > div > h1 {
    font-size: 1.8rem;
  }

  .charliepage__sec3-top > div > p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 750px) {
  .charliepage {
    padding: 0 1rem;
  }

  .charliepage__sec2,
  .charliepage__sec4,
  .charliepage__sec5,
  .charliepage__sec6 {
    margin-bottom: 2rem;
  }

  .charliepage__sec13 {
    margin-bottom: 0rem;
    margin-top: -1rem;
  }

  .charliepage__sec3-top img {
    height: 11rem;
  }

  .charliepage__sec3-top {
    gap: 1rem;
  }

  .charliepage__sec3-top > div {
    height: 11rem;
  }

  .charliepage__sec3-top > div > h1 {
    font-size: 1rem;
  }

  .charliepage__sec3-top > div {
    justify-content: end;
  }

  .charliepage__sec3-top > div > p {
    display: none;
  }

  .charliepage__sec3 > p {
    display: block;
    color: white;
    margin-top: 1.4rem;
    font-size: 1rem;
    text-align: center;
    font-family: "Manrope";
  }

  .charliepage__sec3 {
    margin-bottom: 1.5rem;
  }

  .charliepage__sec5 > p {
    font-size: 0.9rem;
  }

  .charliepage__sec5 > img {
    width: 11rem;
  }

  .charliepage__sec6 {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .charliepage__sec6-top {
    grid-column: 1 / 2;
    width: 100%;
  }

  .charliepage__sec6-top + video {
    left: 0;
    top: 1rem;
    width: 90%;
    margin: 0 auto;
  }

  .charliepage__sec6-bottom {
    top: 2rem;
    width: 90%;
    margin: 0 auto;
  }

  .charliepage__sec7 {
    gap: 1.4rem;
    margin-bottom: 2rem;
  }

  .charliepage__sec7 > p {
    font-size: 1.1rem;
    margin-top: 2rem;
    position: relative;
    left: 1rem;
  }

  .charliepage__sec8 {
    gap: 2rem;
    margin-bottom: 10rem;
  }

  .charliepage__sec8 > h1 {
    font-size: 5rem;
  }

  .charliepage__sec8 > div:nth-of-type(1) > span {
    left: 1rem;
    top: 3rem;
  }

  .charliepage__sec8 > div:nth-of-type(2) > span {
    right: 1.4rem;
    top: 3rem;
  }

  .charliepage__sec8 > div:nth-of-type(1) > span,
  .charliepage__sec8 > div:nth-of-type(2) > span {
    font-size: 1.1rem;
  }

  .charliepage__sec8 > div:nth-of-type(1) > img,
  .charliepage__sec8 > div:nth-of-type(2) > img {
    max-width: 20rem;
    min-width: 15rem;
  }

  .charliepage__sec8 > div:nth-of-type(2) > p {
    bottom: -8rem;
    right: 2rem;
  }
}
