.thoughtcitysection9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  margin-bottom: 5rem;
  width: 100%;
}

.thoughtcitysection9 > div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.thoughtcitysection9 > div > img:nth-of-type(1) {
  width: 40%;
}

.thoughtcitysection9 > div > img:nth-of-type(2) {
  width: 61%;
}

.thoughtcitysection9 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.2rem;
}

@media screen and (max-width: 750px) {
  .thoughtcitysection9 > div {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
  }

  .thoughtcitysection9 > div > img:nth-of-type(1) {
    width: 100%;
  }

  .thoughtcitysection9 > div > img:nth-of-type(2) {
    width: 100%;
  }

  .thoughtcitysection9 > p {
    font-size: 0.9rem;
  }

  .thoughtcitysection9 {
    gap: 2rem;
    margin-bottom: 2rem;
  }
}
