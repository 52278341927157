.thoughtcitysection15__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -15rem;
}

.thoughtcitysection15 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;
  position: relative;
  top: -16rem;
}

.thoughtcitysection15__wrapper > img {
  width: 101%;
  object-fit: contain;
  margin-bottom: 6rem;
}

.thoughtcitysection15 > img {
  width: 60%;
}

.thoughtcitysection15 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection15 > p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1100px) {
  .thoughtcitysection15__wrapper {
    margin-bottom: -18rem;
  }
}

@media screen and (max-width: 1520px) {
  .thoughtcitysection15 {
    margin-top: 2rem;
  }

  .thoughtcitysection15 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .thoughtcitysection15__wrapper {
    margin-bottom: -21rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection15__wrapper {
    margin-bottom: 2rem;
  }
  .thoughtcitysection15 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0;
    position: static;
    margin-bottom: 0rem;
  }

  .thoughtcitysection15 > img {
    width: 100%;
  }

  .thoughtcitysection15__wrapper > img {
    margin-bottom: 2rem;
  }
}
