.thoughtcitysection12 {
  width: 100%;
  object-fit: contain;
  margin-bottom: 6rem;
}

@media screen and (max-width: 750px) {
  .thoughtcitysection12 {
    margin-bottom: 1rem;
    width: 124vw;
  }
}
