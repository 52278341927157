.thoughtcitysection7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-bottom: 6rem;
}

.thoughcitysection7__outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100%;
}

.thoughcitysection7__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.thoughcitysection7__inner > img {
  width: 55%;
}

.thoughcitysection7__inner > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.2rem;
}

.thoughcitysection7__innerdesign {
  width: 100%;
  height: 0.9rem;
  border: 1px solid #b0db36;
}

@media screen and (max-width: 750px) {
  .thoughtcitysection7 {
    margin-bottom: 2rem;
    gap: 2rem;
  }

  .thoughcitysection7__outer {
    gap: 2rem;
  }

  .thoughcitysection7__inner > p {
    font-size: 0.9rem;
  }

  .thoughcitysection7__innerdesign {
    height: 0.6rem;
  }
}
