.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: 100%;
  margin-bottom: 4rem;
}

.footer > img {
  width: 35%;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.footer__bottom > ul {
  list-style: none;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 4rem;
}

.footer__bottom > ul li {
  cursor: pointer;
  color: white;
}

.footer__bottom > ul:nth-of-type(1) {
  font-size: 2.6rem;
}

.footer__bottom > ul:nth-of-type(2) {
  text-decoration: underline;
  font-weight: var(--font-bold);
  position: relative;
  top: -1rem;
}

.footer__bottom > ul:nth-of-type(2) > li {
  letter-spacing: -0.05em;
}

@media screen and (max-width: 1200px) {
  .footer__bottom > ul:nth-of-type(1) {
    font-size: 1.8rem;
  }

  .footer__bottom > ul:nth-of-type(2) {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 750px) {
  .footer__bottom > ul {
    gap: 0.8rem;
  }

  .footer__bottom > ul:nth-of-type(1) {
    font-size: 1rem;
  }

  .footer__bottom > ul:nth-of-type(2) {
    font-size: 1.8rem;
    position: relative;
    top: -0.4rem;
  }
}
