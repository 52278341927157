.spoofsensepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 6rem;
  width: 100%;
}

.spoofsensepage__sec2 {
  width: 100%;
  object-fit: contain;
  margin-bottom: 6rem;
}

.spoofsense-last {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 4rem;
}

.spoofsense-last > img {
  width: 60%;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .spoofsensepage {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 750px) {
  .spoofsensepage {
    padding: 0 1rem;
  }

  .spoofsense-last {
    margin-top: 0rem;
  }
}
