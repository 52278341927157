.thoughtcitysection3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 6rem;
}

.thoughtcitysection3 > img {
  width: 80%;
}

.thoughtcitysection3 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
  position: relative;
  top: 2rem;
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection3 > p {
    font-size: 0.9rem;
    width: 100%;
  }

  .thoughtcitysection3 > img {
    width: 75%;
  }
}

@media screen and (max-width: 820px) {
  .thoughtcitysection3 {
    margin-top: 2rem;
  }

  .thoughtcitysection3 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection3 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .thoughtcitysection3 > img {
    width: 100%;
  }
}
