.thoughtcitysection14 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;
  margin-top: -10rem;
}

.thoughtcitysection14 > img {
  width: 65%;
}

.thoughtcitysection14 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
}

@media screen and (max-width: 1420px) {
  .thoughtcitysection14 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection14 > p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection14 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .thoughtcitysection14 > img {
    width: 100%;
  }
}
