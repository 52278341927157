.sandboxpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 6rem;
  width: 100%;
  font-family: "Familjen Grotesk";
  letter-spacing: -0.05em;
  margin-top: 1.5rem;
}

.sandboxpage__section1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 80%;
  color: white;
  margin-bottom: 6rem;
}

.sandboxpage__section1 > video {
  width: 100%;
}

.sandboxpage__section1 > p {
  font-size: 2rem;
  font-family: "Familjen Grotesk";
}

.sandboxpage__section2 {
  width: 100%;
  margin-bottom: 6rem;
}

.sandboxpage__section2 > img {
  width: 50%;
}

.sandboxpage__section2 > div:nth-of-type(1) {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  position: relative;
  left: 9rem;
  top: -7rem;
}

.sandboxpage__section2 > div:nth-of-type(1) > p {
  font-size: 2rem;
  color: white;
  letter-spacing: -0.05em;
}

.sandboxpage__section2 > div:nth-of-type(2) {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.sandboxpage__section2 > div:nth-of-type(2) > p {
  color: white;
  font-size: 2rem;
  position: relative;
  top: -2rem;
}

.sandboxpage__section2 > div:nth-of-type(3) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
}

.sandboxpage__section2 > div:nth-of-type(3) > img {
  width: 45%;
}

.sandboxpage__section2 > div:nth-of-type(3) > img:nth-of-type(2) {
  position: relative;
  top: -8rem;
}

.sandboxpage__section2-carousel {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  width: 40%;
  overflow-x: scroll;
  padding: 1rem;
}

.sandboxpage__section2-carousel > img {
  width: 100%;
}

.sandboxpage *::-webkit-scrollbar {
  width: 1rem;
  height: 0.5rem;
}

.sandboxpage *::-webkit-scrollbar-track-piece {
  background: #131313;
}

.sandboxpage *::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
}

@media screen and (max-width: 1300px) {
  .sandboxpage__section2 > div:nth-of-type(1) {
    top: -6rem;
  }
}

@media screen and (max-width: 1000px) {
  .sandboxpage {
    padding: 0 4rem;
  }
  .sandboxpage__section2 > div:nth-of-type(1) {
    top: -5rem;
  }
}

@media screen and (max-width: 750px) {
  .sandboxpage {
    padding: 0 1rem;
  }

  .sandboxpage__section1 {
    margin-bottom: 2rem;
    width: 100%;
  }

  .sandboxpage__section1 > p {
    font-size: 1rem;
  }

  .sandboxpage__section2 {
    margin-bottom: 2rem;
  }

  .sandboxpage__section2 > img {
    width: 80%;
  }

  .sandboxpage__section2 > div:nth-of-type(1) {
    justify-content: center;
    position: static;
    gap: 1rem;
  }

  .sandboxpage__section2 > div:nth-of-type(1) > p {
    font-size: 1.2rem;
  }

  .sandboxpage__section2-carousel {
    width: 100%;
    padding: 0.5rem;
    margin-top: 1rem;
  }

  .sandboxpage__section2 > div:nth-of-type(2) {
    justify-content: center;
  }

  .sandboxpage__section2 > div:nth-of-type(2) > p {
    position: static;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
  }

  .sandboxpage__section2 > div:nth-of-type(3) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .sandboxpage__section2 > div:nth-of-type(3) > img {
    width: 100%;
  }

  .sandboxpage__section2 > div:nth-of-type(3) > img:nth-of-type(2) {
    position: static;
    margin-top: 2rem;
  }
}
