.thoughtcitysection16 {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8rem;
  width: 100%;
  margin-bottom: 6rem;
}

.thoughtcitysection16 > img {
  width: 60%;
}

.thoughtcitysection16 > p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection16 > p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1120px) {
  .thoughtcitysection16 {
    margin-top: 2rem;
  }

  .thoughtcitysection16 > p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 950px) {
  .thoughtcitysection16 {
    gap: 5rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection16 {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .thoughtcitysection16 > img {
    width: 100%;
  }
}
