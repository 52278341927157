.charliesection9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 9rem;
}

.charliesection9 > img {
  width: 55%;
  position: relative;
  top: 4rem;
}

.charliesection9 > div:nth-of-type(1),
.charliesection9 > div:nth-of-type(2) {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  gap: 8rem;
  font-family: "Manrope";
  font-size: 1.2rem;
  height: max-content;
}

.charliesection9 > div:nth-of-type(2) {
  align-items: flex-end;
}

.charliesection9 > div:nth-of-type(1) > img,
.charliesection9 > div:nth-of-type(2) > img {
  width: 75%;
}

.charliesection9 > div:nth-of-type(1) > img {
  position: relative;
  left: 2rem;
}

.charliesection9 > div:nth-of-type(2) > img {
  position: relative;
  right: 2rem;
}

@media screen and (max-width: 1220px) {
  .charliesection9 > div:nth-of-type(1),
  .charliesection9 > div:nth-of-type(2) {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1120px) {
}

@media screen and (max-width: 1000px) {
  .charliesection9 > div:nth-of-type(1),
  .charliesection9 > div:nth-of-type(2) {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 820px) {
}

@media screen and (max-width: 750px) {
  .charliesection9 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin: 3rem 0;
  }

  .charliesection9 > img {
    width: 90%;
    top: 1rem;
  }

  .charliesection9 > div:nth-of-type(1),
  .charliesection9 > div:nth-of-type(2) {
    flex-direction: row;
    gap: 1.4rem;
  }

  .charliesection9 > div:nth-of-type(1) > img,
  .charliesection9 > div:nth-of-type(2) > img {
    width: 40%;
  }

  .charliesection9 > div:nth-of-type(1) > img {
    left: 0rem;
  }

  .charliesection9 > div:nth-of-type(2) > img {
    right: 0rem;
  }
}
