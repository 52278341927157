.thoughtcitysection6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-bottom: 6rem;
}

.thoughtcitysection6 p {
  color: white;
  font-family: "Manrope";
  font-size: 1.1rem;
  height: fit-content;
  text-align: start;
}

.thoughtcitysection6 > img {
  height: 28rem;
  object-fit: contain;
}

.thoughtcitysection6 > div {
  height: 19rem;
}

.thoughtcitysection6 > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  left: 4rem;
}

.thoughtcitysection6 > div:nth-of-type(2) {
  display: none;
}

@media screen and (max-width: 1220px) {
  .thoughtcitysection6 p {
    font-size: 1rem;
  }

  .thoughtcitysection6 > img {
    height: 24rem;
  }

  .thoughtcitysection6 > div {
    height: 17rem;
  }

  .thoughtcitysection6 > div:nth-of-type(1) {
    left: 2rem;
  }
}

@media screen and (max-width: 1120px) {
  .thoughtcitysection6 p {
    font-size: 0.9rem;
  }

  .thoughtcitysection6 > img {
    height: 20rem;
  }

  .thoughtcitysection6 > div {
    height: 17rem;
  }
}

@media screen and (max-width: 950px) {
  .thoughtcitysection6 > img {
    height: 19rem;
  }

  .thoughtcitysection6 > div {
    height: 14rem;
  }
}

@media screen and (max-width: 890px) {
  .thoughtcitysection6 p {
    font-size: 0.7rem;
  }

  .thoughtcitysection6 > img {
    height: 17rem;
  }

  .thoughtcitysection6 > div {
    height: 14rem;
  }
}

@media screen and (max-width: 750px) {
  .thoughtcitysection6 {
    flex-direction: column;
    justify-content: center;
    gap: 0rem;
    margin-bottom: 3.5rem;
  }

  .thoughtcitysection6 p {
    font-size: 0.9rem;
  }

  .thoughtcitysection6 > img {
    width: 100%;
    height: auto;
  }

  .thoughtcitysection6 > div {
    height: auto;
    width: 100%;
  }

  .thoughtcitysection6 > div:nth-of-type(1) > p:nth-of-type(2) {
    display: none;
  }

  .thoughtcitysection6 > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .thoughtcitysection6 > div:nth-of-type(1) {
    left: 1rem;
  }
}
